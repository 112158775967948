@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: "Inter";
  /* , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-link {
  font-weight: 400;
}

/* @media (min-width: 768px) {
  .nav-font-size {
    font-size: 13px;
  }  
} */

@media (min-width: 768px) {
  .nav-font-size {
    font-size: 12px;
  }
  .header-logo {
    height: 35px;
    padding: 0px;
    margin-right: 5px;
  }
  .header-font {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .nav-font-size {
    font-size: 14px;
  }
  .header-logo {
    height: 45px;
    padding: 0px;
    margin-right: 7px;
  }
  .header-font {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .nav-font-size {
    font-size: 16px;
  }
  .header-logo {
    height: 50px;
    padding: 0px;
    margin-right: 10px;
  }
  .header-font {
    font-size: 24px;
  }
}
@media (min-width: 768px) {
  .pricing {
    width: 85%;
  }
}

.pricing-icon {
  border: 2px solid currentColor;
  border-radius: 50%;
  padding: 4px;
  color: white;
  background-color: #0d6efd;
  display: inline-block;
  width: 28px;
  height: 28px;
  margin-right: 5px;
}

.faq-title {
  text-align: center;
  font-weight: 700;
  font-size: 27px;
}
.faq-questions {
  font-weight: 600;
  font-size: 20px;
}
.faq-answers {
  font-weight: 300;
  font-size: 17px;
}
@media (min-width: 768px) {
  .faq {
    width: 80%;
  }
  .faq-questions {
    font-size: 24px;
  }
  .faq-answers {
    font-size: 18px;
  }
  .faq-title {
    font-size: 35px;
  }
}
.homeview-tiles {
  width: 90%;
  height: auto;
  margin: 0 auto;
}
.tiles-title {
  font-weight: 600;
}
.tiles-content {
  font-weight: 400;
}

@media (max-width: 992px) {
  .tiles-content {
    padding-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .homeview-tiles {
    width: 32%;
  }
}

@media (max-width: 768px) {
  .text-size {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 30px;
  }
  .subtitle {
    font-size: 15px;
  }
}
@media (min-width: 992px) {
  .title {
    font-size: 40px;
  }
  .subtitle {
    font-size: 17px;
  }
}

@media (min-width: 1200px) {
  .title {
    font-size: 50px;
  }
  .subtitle {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .title {
    text-align: center;
  }
  .subtitle {
    text-align: center;
  }
}

.hljs {
  background: #F5F5F5 !important;
  border: 1px solid #E0E0E0 !important;
}